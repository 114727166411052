import React, { Suspense, useEffect } from 'react'

import {
  makeStyles,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Checkbox,
  Typography,
  Collapse,
  ListItemIcon,
  Theme,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { useAppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'
import useDoc from 'hooks/useDoc'
import useCollection from 'hooks/useCollection'
import _groupBy from 'lodash/groupBy'
import { Loading, ProfileCard } from '@antlerengineering/components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    listHeader: {
      cursor: 'pointer',
    },
    link: {
      textDecoration: 'none',
    },
  })
)

function ChecklistCard({ ...cardProps }: any) {
  const classes = useStyles()
  const { userDocState, updateUserDoc, cohortDoc } = useAppContext()
  const userDoc = userDocState.doc
  console.log(cohortDoc.id)
  const [expanded, setExpanded] = React.useState<any>({ ['Quick Start']: true })

  const handleExpandClick = (key: string) => {
    setExpanded({ [key]: !expanded[key] })
  }

  const [tasksState, tasksDispatch] = useCollection({})
  useEffect(() => {
    if (cohortDoc) {
      tasksDispatch({
        path: `cohorts/${cohortDoc?.id}/tasks`,
        filters: [{ field: 'published', operator: '==', value: true }],
      })
    }
  }, [cohortDoc])

  const taskDocs = tasksState.documents
  // const {
  //   data: { data },
  // } = useColHook({
  //   type: 'actions',
  //   filters: [{ field: 'published', operator: '==', value: true }],
  // })
  const filtered = taskDocs.filter(
    item => !userDoc.checked?.[item.id as string]
  )
  const groupedActions: Record<string, any[]> = _groupBy(taskDocs, 'category')

  const handleChange = (id: string) => {
    updateUserDoc &&
      updateUserDoc({
        checked: {
          ...userDoc.checked,
          [id]: !userDoc.checked?.[id],
        },
      })
  }

  return taskDocs.length === 0 ? null : (
    <Grid item sm={12} md={6} style={{ padding: 8 }}>
      <ProfileCard
        overline="Founder checklist"
        body={
          <List>
            {Object.keys(groupedActions)
              .sort((a, b) => a.localeCompare(b))
              .map(group => (
                <>
                  <ListItem>
                    <ListItemText
                      className={classes.listHeader}
                      onClick={() => handleExpandClick(group)}
                      primary={
                        <>
                          <Typography variant="h6" color="secondary">
                            {group}
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded[group],
                              })}
                              aria-expanded={expanded[group]}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </Typography>
                        </>
                      }
                    />

                    <ListItemIcon></ListItemIcon>
                  </ListItem>
                  <Collapse in={expanded[group]} timeout="auto" unmountOnExit>
                    <List>
                      {groupedActions[group]
                        ?.sort((a, b) => a.order - b.order)
                        .map(item => (
                          <ListItem>
                            <ListItemAvatar>
                              {item?.icon?.length > 0 ? (
                                <img
                                  src={item?.icon?.[0]?.downloadURL}
                                  width={20}
                                  height={20}
                                  alt="Checklist Item"
                                />
                              ) : (
                                <AssignmentTurnedInIcon />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                item?.internalLink ? (
                                  <Link className={classes.link} to={item.link}>
                                    <Typography color="textSecondary">
                                      {item.title}
                                    </Typography>
                                  </Link>
                                ) : (
                                  <a
                                    className={classes.link}
                                    href={item?.link}
                                    target="_blank"
                                  >
                                    <Typography color="textSecondary">
                                      {item.title}
                                    </Typography>
                                  </a>
                                )
                              }
                            />
                            <ListItemIcon>
                              <Checkbox
                                checked={userDoc.checked?.[item.id as string]}
                                onChange={() => handleChange(item.id)}
                              />
                            </ListItemIcon>
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </>
              ))}
          </List>
        }
        {...cardProps}
      />
    </Grid>
  )
}

export default () => (
  <Suspense fallback={<Loading />}>
    <ChecklistCard />
  </Suspense>
)
