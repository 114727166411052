import React, { useEffect, useState, useContext } from 'react'
import { auth } from '../firebase'
import useDoc from '../hooks/useDoc'
import useCollection from '../hooks/useCollection'
import useAlgolia from 'use-algolia'
import smartlookClient from 'smartlook-client'
import { cloudFunction, CLOUD_FUNCTIONS } from 'firebase/callables'
import { getNavItems, Route } from 'constants/routes'
import { useSnackContext } from 'samosas'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
import { getLaunchDarkByUser } from 'contexts/DarkLaunch'

interface IAppProviderProps {
  children: React.ReactNode
}
export type CustomClaims = {
  roles: string[]
  regions: string[]
  isFunded: boolean | undefined
  unFunded: boolean | undefined
  teamId: string | undefined
}
interface IAppContextInterface {
  algoliaKeys: Record<string, string>
  currentUser: firebase.default.User | null | undefined
  userDocState: any | null | undefined
  updateUserDoc?: (data: any) => void
  cohortDoc: any | null | undefined
  foundersCollection: any
  cohortCollectionState: any | undefined
  navItems: Route[]
  userClaims: CustomClaims | undefined
  showAntlerNetwork: boolean
}

export const AppContext = React.createContext<IAppContextInterface>({
  algoliaKeys: {},
  foundersCollection: [],
  currentUser: undefined,
  userDocState: undefined,
  updateUserDoc: undefined,
  cohortDoc: undefined,
  cohortCollectionState: undefined,
  navItems: [],
  userClaims: undefined,
  showAntlerNetwork: false,
})

export const useAppContext = () => useContext(AppContext)

const getUserType = (roles: string[]) => {
  if (roles.includes('TEAM')) return 'Team'
  if (roles.includes('ADVISOR')) return 'Advisor'
  if (roles.includes('FOUNDER')) return 'Founder'
  return 'Unknown'
}
export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<
    null | undefined | firebase.default.User
  >()
  const [userClaims, setUserClaims] = useState<CustomClaims>()
  const [userDocState, userDocDispatch, updateUserDoc] = useDoc({})
  const [cohortCollectionState, cohortDispatch] = useCollection({})
  const [algoliaKeys, setAlgoliaKeys] = useState<Record<string, string>>({})
  const [showAntlerNetwork, setShowAntlerNetwork] = useState(false)

  const snack = useSnackContext()

  const [navItems, setNavItems] = useState<Route[]>([])
  useEffect(() => {
    const result = getNavItems({
      userClaims,
      algoliaKeys,
      userDoc: userDocState.doc,
      showAntlerNetwork,
    })
    if (JSON.stringify(result) !== JSON.stringify(navItems)) {
      setNavItems(result)
    }
  }, [userClaims, algoliaKeys, JSON.stringify(navItems), showAntlerNetwork])

  const [algoliaState, requestDispatch, , setAlgoliaConfig] = useAlgolia(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    '',
    'founders',
    {
      hitsPerPage: 500,
    }
  )
  const foundersCollection = {
    algoliaState,
    documents: algoliaState.hits.map(hit => ({ ...hit, id: hit.objectID })),
    requestDispatch,
  }

  useEffect(() => {
    if (
      userDocState.doc !== null &&
      userDocState.doc !== undefined &&
      userClaims !== null &&
      userClaims !== undefined &&
      currentUser !== null &&
      currentUser !== undefined
    ) {
      posthog.identify(currentUser.uid, {
        name: currentUser.displayName,
        first_name: userDocState.doc.firstName,
        last_name: userDocState.doc.lastName,
        email: currentUser.email,
        Regions: userDocState.doc.region,
        Cohort: userDocState.doc.cohort,
        Role: getUserType(userClaims.roles),
        TrackedOut: userDocState.doc.isTrackedOut,
        Funded: userDocState.doc.isFunded,
        StartupIndustryFocus: userDocState.doc.startupIndustryFocus,
        CountriesLivedIn: userDocState.doc.countriesLivedIn,
        Education: userDocState.doc.education,
        FounderType: userDocState.doc.founderType,
        IndustryExperience: userDocState.doc.industryExperience,
        IdeaToProgram: userDocState.doc.ideaToProgram,
      })
      Sentry.setUser({
        name: currentUser.displayName,
        email: currentUser.email!,
        Regions: userDocState.doc.region,
        Cohort: userDocState.doc.cohort,
        Role: getUserType(userClaims.roles),
      })

      getLaunchDarkByUser({
        userEmail: currentUser.email!.toLowerCase(),
        clientVariation: 'antlerNetwork',
      }).then(res => {
        if (res) {
          setShowAntlerNetwork(true)
        }
      })
    }
  }, [userDocState.doc, userClaims, currentUser])

  useEffect(() => {
    auth.onAuthStateChanged(async auth => {
      setCurrentUser(auth)

      if (auth) {
        window.analytics.identify(auth.uid, {
          name: auth.displayName,
          email: auth.email,
        })

        const token = await auth.getIdTokenResult(true)
        setUserClaims(token?.claims as CustomClaims)

        const roles = token.claims.roles
        if (!roles || roles.length === 0) {
          snack.open({
            message:
              'This account was not properly set up. There are no user permissions.',
            severity: 'error',
          })
        } else {
          if (roles.includes('FOUNDER')) {
            userDocDispatch({ path: `founders/${auth.uid}` })
          } else if (roles.includes('ADVISOR')) {
            userDocDispatch({ path: `advisors/${auth.uid}` })
          } else if (roles.includes('TEAM')) {
            userDocDispatch({ path: `employees/${auth.uid}` })
          }
        }
      } else {
        posthog.reset()
      }
    })
  }, [])

  const handleAlgoliaKeys = data => {
    setAlgoliaKeys(
      data.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.indices.reduce(
            (accIndices, currIndex) => ({
              ...accIndices,
              [currIndex]: curr.key,
            }),
            {}
          ),
        }
      }, {})
    )
  }
  useEffect(() => {
    if (userDocState.doc) {
      if (userDocState.doc && userDocState.doc.algoliaKeys) {
        // console.log(userDocState.doc.algoliaKeys)
        handleAlgoliaKeys(userDocState.doc.algoliaKeys.keys)
      } else {
        // console.log(
        //   `calling ${CLOUD_FUNCTIONS.getAlgoliaKeys}:${
        //     userDocState.path.split('/')[0]
        //   }`
        // )
        cloudFunction(
          CLOUD_FUNCTIONS.getAlgoliaKeys,
          {
            userGroup: userDocState.path.split('/')[0],
          },
          resp => {
            //console.log(resp.data)
            //handleAlgoliaKeys(resp.data.data)
          },
          error => console.log(error)
        )
      }
      // console.log({ userDoc: userDocState.doc })
    }
  }, [userDocState.doc])

  // useEffect(() => {
  //   const { doc, path } = userDocState
  //   const cohortDoc = cohortCollectionState.documents[0]
  //   if (doc !== null && cohortDoc) {
  //     if (path.includes('founders')) {
  //       if (doc.isFunded) {
  //         setHubMode(hubModes.phaseTwo)
  //       } else {
  //         if (doc.unfunded) {
  //           setHubMode(hubModes.unFunded)
  //         } else {
  //           setHubMode(hubModes.phaseOne)
  //         }
  //       }
  //     }
  //   }
  // }, [userDocState.doc, cohortCollectionState.documents])

  useEffect(() => {
    if (algoliaKeys.founders) {
      setAlgoliaConfig({
        searchKey: algoliaKeys.founders,
      })
    }
  }, [algoliaKeys])
  useEffect(() => {
    if (userDocState.doc) {
      const { firstName, lastName, cohort, email } = userDocState.doc
      smartlookClient.identify(userDocState.doc.id, {
        name: `${firstName} ${lastName}`,
        cohort,
        email,
      })
    }
    if (userDocState.doc && userDocState.doc.cohort) {
      cohortDispatch({
        path: 'cohorts',
        filters: [
          {
            value: userDocState.doc.cohort,
            operator: '==',
            field: 'cohort',
          },
        ],
      })
    }
  }, [userDocState.doc])
  return (
    <AppContext.Provider
      value={{
        userClaims,
        navItems,
        foundersCollection,
        currentUser,
        userDocState,
        updateUserDoc,
        algoliaKeys,
        cohortDoc: cohortCollectionState.documents[0]
          ? cohortCollectionState.documents[0]
          : null,
        cohortCollectionState,
        showAntlerNetwork,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const tokenHasValue = async (key: string, value: unknown) => {
  const user = auth.currentUser
  if (user) {
    const token = await user.getIdTokenResult()
    if (token.claims[key] && !token.claims[key].includes(value)) {
      user.getIdTokenResult(true)
    }
  }
}

export const refreshToken = () => {
  const user = auth.currentUser
  if (user) user.getIdTokenResult(true)
}
