import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

import { getAuthLinkJWT } from 'firebase/callables'
import { Loading, EmptyState } from '@antlerengineering/components'
import { auth } from '../../firebase'
enum ViewStates {
  fetching = 'FETCHING',
  authenticating = 'AUTHENTICATING',
  expired = 'expired',
  invalid = 'INVALID',
}

export default function AuthLinkView({ location }: RouteComponentProps) {
  const [state, setState] = useState(ViewStates.fetching)

  const handleAuth = async (id, key) => {
    const resp = await getAuthLinkJWT(id, key)
    if (resp.data.success) {
      setState(ViewStates.authenticating)
      await auth.signInWithCustomToken(resp.data.jwt)

      const { redirect } = queryString.parse(location.search)

      window.location.replace(
        redirect
          ? decodeURIComponent(redirect as string)
          : resp.data.redirectPath
      )
    } else {
      setState(ViewStates.invalid)
    }
  }
  useEffect(() => {
    const { id, key } = queryString.parse(window.location.search)

    if (typeof id === 'string' && typeof key === 'string') {
      handleAuth(id, key)
    } else {
      setState(ViewStates.invalid)
    }
  }, [])

  switch (state) {
    case ViewStates.fetching:
      return <Loading fullScreen message="Getting your key" />
    case ViewStates.authenticating:
      return <Loading fullScreen message="Authenticating" />
    case ViewStates.expired:
    case ViewStates.invalid:
      return <EmptyState fullScreen message="Invalid or expired Link" />
    default:
      return <EmptyState fullScreen message="fail" />
  }
}
