import React, { useState, useEffect } from 'react'

import {
  makeStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'

import { Modal, CtaButton, Loading } from '@antlerengineering/components'

import { useAppContext } from 'contexts/AppContext'
import { cloudFunction, CLOUD_FUNCTIONS } from 'firebase/callables'
import useRouter from 'hooks/useRouter'
import { routes } from 'constants/routes'

const useStyles = makeStyles(theme =>
  createStyles({
    checkbox: {
      marginBottom: theme.spacing(4),
      '& + &': { marginTop: theme.spacing(-3) },
    },
    label: theme.typography.body1,

    signUp: {
      display: 'flex',
      margin: '0 auto',
      marginBottom: theme.spacing(4),
      width: '100%',
      maxWidth: 300,
    },

    submitting: { padding: theme.spacing(4) },
  })
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />
})

export default function TalentNetworkPrompt() {
  const classes = useStyles()

  const { userDocState, currentUser } = useAppContext()
  const userDoc = userDocState.doc
  const router = useRouter()

  const [open, setOpen] = useState(true)
  useEffect(() => {
    if (router.location.pathname.includes(routes.profile) && !open)
      setOpen(true)
  }, [router.location.pathname])

  const [consent, setConsent] = useState(false)
  const [newsletter, setNewsletter] = useState(false)
  const [signup, setSignup] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const handleClose = () => setOpen(false)
  const handleSignUp = () => {
    setSubmitting(true)
    cloudFunction(
      CLOUD_FUNCTIONS.joinTalentNetwork,
      { uid: currentUser!.uid, newsletter },
      () => {
        setOpen(false)
        setSubmitting(false)
        router.history.push(routes.profileTalentNetwork)
      },
      () => {
        alert('Failed')
        setSubmitting(false)
      }
    )
  }

  return (
    <>
      <Modal
        onClose={handleClose}
        disableBackdropClick
        title="Sign up to Antler Talent Network"
        body={
          <>
            <section>
              <Typography paragraph>
                Dear {userDoc.preferredName || userDoc.firstName},
              </Typography>
              <Typography paragraph>
                We believe our paths crossed for a reason, hence we would like
                to invite you to be part of our Talent Network: an invite-only
                platform offering plenty of benefits for high achieving
                individuals like yourself who want to stay engaged with Antler
                and discover future opportunities with Antler’s portfolio
                companies.
              </Typography>
              <Typography paragraph>
                We wish you success in all your future endeavours.
              </Typography>
            </section>

            <section>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={signup}
                    onChange={() => setSignup(!signup)}
                    name="signup"
                    color="primary"
                  />
                }
                label="Yes, sign me up to the Antler Talent Network"
                classes={{ root: classes.checkbox, label: classes.label }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newsletter}
                    onChange={() => setNewsletter(!newsletter)}
                    name="newsletter"
                    color="primary"
                  />
                }
                label="I would like to receive news about the Antler Talent Network"
                classes={{ root: classes.checkbox, label: classes.label }}
              />
            </section>

            <section>
              <Typography variant="body2" color="textSecondary" paragraph>
                In order to comply with privacy regulation in the European Union
                (“EU”), we require that you provide your consent before
                providing your data for processing. Please refer to our Privacy
                Notice for more detailed information. If you wish to change your
                consent or you have any questions, please contact us by email at
                dpo@antler.co or by writing to us in Europe at Antler AS, Edvard
                Storms gate 2, 0166 Oslo, Norway at any time.
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                    name="consent"
                    color="primary"
                  />
                }
                label="Yes, I consent to Antler storing processing my data
              for the Antler Talent Network"
                classes={{ root: classes.checkbox, label: classes.label }}
              />
            </section>
          </>
        }
        footer={
          <div style={{ textAlign: 'center' }}>
            <CtaButton
              onClick={handleSignUp}
              size="medium"
              disabled={!consent || !signup}
            >
              Create My Profile
            </CtaButton>
          </div>
        }
      />

      {submitting && (
        <Modal
          onClose={() => {}}
          TransitionComponent={Transition}
          aria-label="Submitting"
          title=""
          body={
            <Loading
              message="Creating Your Profile"
              className={classes.submitting}
            />
          }
          scroll="body"
          fullWidth={false}
          maxWidth="xs"
          disableBackdropClick
          hideCloseButton
        />
      )}
    </>
  )
}
