import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import smartlookClient from 'smartlook-client'
import posthog from 'posthog-js';

import {
  CssBaseline,
  MuiThemeProvider as ThemeProvider,
} from '@material-ui/core'
import Theme from './Theme'
import MaintenanceIcon from '@material-ui/icons/SyncDisabledOutlined'

import { AppProvider } from './contexts/AppContext'
import { FiregridProvider } from 'contexts/FiregridContext'
import CustomBrowserRouter from './utils/CustomBrowserRouter'
import SegmentPageTracker from 'utils/SegmentPageTracker'
import PrivateRoute from './utils/PrivateRoute'

import { SnackProvider } from 'samosas'

import GlobalStyles from './utils/GlobalStyles'
import HomeRedirect from './utils/HomeRedirect'
import { routes } from './constants/routes'

import {
  ErrorBoundary,
  Loading,
  EmptyState,
} from '@antlerengineering/components'
import Navigation from './components/Navigation'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import AnnouncementsView from './views/AnnouncementsView'
import ProgramCalendarView from './views/EventsViews/ProgramCalendarView'
import DiscourseSSO from 'views/DiscourseSSO'

import ForgotPasswordView from 'views/AuthViews/ForgotPasswordView'
import ResetPasswordView from 'views/AuthViews/ResetPasswordView'
import TransferAccountView from 'views/AuthViews/TransferAccountView'
import AuthLinkView from 'views/AuthViews/AuthLinkView'
import GoogleAuthView from 'views/AuthViews/GoogleAuthView'
import JWTAuthView from 'views/AuthViews/JWTAuth'
import SignInView from 'views/AuthViews/SignInView'
import SignOutView from './views/SignOutView'
import AdminAuthView from 'views/AuthViews/AdminAuthView'

const ProfilePersonalView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ProfileViews/PersonalView" */
    './views/ProfileViews/PersonalView'
  )
)
const ProfileProgramView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ProfileViews/ProgramView" */
    './views/ProfileViews/ProgramView'
  )
)
const ProfileStartupView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ProfileViews/StartupView" */
    './views/ProfileViews/StartupView'
  )
)
const ProfileTalentNetworkView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ProfileViews/TalentNetworkView" */
    './views/ProfileViews/TalentNetworkView'
  )
)

const FoundersView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "CohortViews/FoundersView" */
    './views/CohortViews/FoundersView'
  )
)
const SubmissionsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "CohortViews/SubmissionsView" */
    './views/CohortViews/SubmissionsView'
  )
)
const TeamsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "CohortViews/TeamsView" */
    './views/CohortViews/TeamsView'
  )
)

const MyTeamStartupView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyTeamViews/StartupView" */
    './views/MyTeamViews/StartupView'
  )
)
const MyTeamDetailsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyTeamViews/DetailsView" */
    './views/MyTeamViews/DetailsView'
  )
)
const MyTeamCoachingView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyTeamViews/CoachingView" */
    './views/MyTeamViews/CoachingView'
  )
)

const MyStartupStartupView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyStartupViews/StartupView" */
    './views/MyStartupViews/StartupView'
  )
)
const MyStartupDetailsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyStartupViews/DetailsView" */
    './views/MyStartupViews/DetailsView'
  )
)
const MyStartupChampionsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyStartupViews/ChampionsView" */
    './views/MyStartupViews/ChampionsView'
  )
)
const MyStartupFundraisingView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyStartupViews/FundraisingView" */
    './views/MyStartupViews/FundraisingView'
  )
)
const MyStartupJobsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "MyStartupViews/JobsView" */
    './views/MyStartupViews/JobsView'
  )
)

const AntlerNetworkFoundersView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AntlerNetworkViews/FoundersView" */
    './views/AntlerNetworkViews/FoundersView'
  )
)

const AntlerNetworkStartupsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AntlerNetworkViews/StartupsView" */
    './views/AntlerNetworkViews/StartupsView'
  )
)

const StartupPage = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AntlerNetworkViews/StartupPageView" */
    './views/AntlerNetworkViews/StartupPageView'
  )
)

const InvestmentDashboardView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "InvestmentViews/DashboardView" */
    './views/InvestmentViews/DashboardView'
  )
)
const IPAntlerStartupsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "InvestmentViews/AntlerStartupsView" */
    './views/InvestmentViews/AntlerStartupsView'
  )
)
const IPMyUpdatesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "InvestmentViews/MyUpdatesView" */
    './views/InvestmentViews/MyUpdatesView'
  )
)
const IPInvestorsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "InvestmentViews/InvestorsView" */
    './views/InvestmentViews/InvestorsView'
  )
)

const TalentNetworkView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "TalentNetworkView" */
    './views/TalentNetworkView'
  )
)

const ProgramResourcesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/ProgramResourcesView" */
    './views/ResourceViews/ProgramResourcesView'
  )
)
const TechResourcesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/TechResourcesView" */
    './views/ResourceViews/TechResourcesView'
  )
)
const LibraryView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/LibraryView" */
    './views/ResourceViews/LibraryView'
  )
)

const GlobalEventsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EventsViews/GlobalEventsView" */
    './views/EventsViews/GlobalEventsView'
  )
)

const PartnershipsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PartnershipsView" */
    './views/PerksViews/PartnershipsView'
  )
)
const OfferPerkView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "OfferPerkView" */
    './views/PerksViews/OfferPerkView'
  )
)
const AdvisorsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AdvisorsView" */
    './views/AdvisorsView'
  )
)
const NPSView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "NPSView" */
    './views/NPSView'
  )
)

// set to true during maintenance and redeploy
const MAINTENANCE_MODE = false

const App: React.FC = () => {
  useEffect(() => {
    smartlookClient.init('0383e82a4e0ccaf6ecc758ad1e35e6e52ea46dfa')
    posthog.init("phc_z12oxx9EzDLiOPGYBHpsIkGa00VjdIpgxkkjkeFMIo5", {api_host: "https://app.posthog.com"});
  }, [])

  if (MAINTENANCE_MODE)
    return (
      <ThemeProvider theme={Theme}>
        <EmptyState
          fullScreen
          Icon={MaintenanceIcon}
          message="Scheduled Maintenance"
          description={
            <>
              Antler Hub is currently under maintenance.
              <br />
              Please come back later.
            </>
          }
        />
      </ThemeProvider>
    )

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <GlobalStyles />
      <ErrorBoundary>
        <SnackProvider>
          <AppProvider>
            <FiregridProvider>
              <DndProvider backend={HTML5Backend} context={window}>
                <CustomBrowserRouter>
                  <SegmentPageTracker />
                  <Suspense fallback={<Loading fullScreen />}>
                    <Switch>
                      <Route
                        exact
                        path={routes.adminAuth}
                        render={() => <AdminAuthView />}
                      />
                      <Route
                        exact
                        path={routes.authLink}
                        render={props => <AuthLinkView {...props} />}
                      />
                      <Route
                        exact
                        path={routes.googleAuth}
                        render={() => <GoogleAuthView />}
                      />
                      <Route
                        exact
                        path={routes.jwtAuth}
                        render={() => <JWTAuthView />}
                      />
                      <Route
                        exact
                        path={routes.forgotPassword}
                        render={() => <ForgotPasswordView />}
                      />
                      <Route
                        exact
                        path={routes.resetPassword}
                        render={() => <ResetPasswordView />}
                      />
                      <Route
                        exact
                        path={routes.transferAccount}
                        render={() => <TransferAccountView />}
                      />
                      <Route
                        exact
                        path={routes.signIn}
                        render={() => <SignInView />}
                      />

                      <Route
                        exact
                        path={routes.signOut}
                        render={() => <SignOutView />}
                      />

                      <PrivateRoute
                        exact
                        path={routes.home}
                        render={() => (
                          <Navigation>
                            <AnnouncementsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.discourse}
                        render={() => <DiscourseSSO />}
                      />

                      <PrivateRoute
                        exact
                        path={routes.profile}
                        render={() => <Redirect to={routes.profilePersonal} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profilePersonal}
                        render={() => (
                          <Navigation>
                            <ProfilePersonalView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profileProgram}
                        render={() => (
                          <Navigation>
                            <ProfileProgramView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profileStartup}
                        render={() => (
                          <Navigation>
                            <ProfileStartupView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profileTalentNetwork}
                        render={() => (
                          <Navigation>
                            <ProfileTalentNetworkView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.cohort}
                        render={() => <Redirect to={routes.cohortFounders} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.cohortFounders}
                        render={() => (
                          <Navigation>
                            <FoundersView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.cohortSubmissions}
                        render={() => (
                          <Navigation>
                            <SubmissionsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.cohortTeams}
                        render={() => (
                          <Navigation>
                            <TeamsView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.myTeam}
                        render={() => <Redirect to={routes.myTeamStartup} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myTeamStartup}
                        render={() => (
                          <Navigation>
                            <MyTeamStartupView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myTeamDetails}
                        render={() => (
                          <Navigation>
                            <MyTeamDetailsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myTeamCoaching}
                        render={() => (
                          <Navigation>
                            <MyTeamCoachingView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.myStartup}
                        render={() => <Redirect to={routes.myStartupStartup} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myStartupStartup}
                        render={() => (
                          <Navigation>
                            <MyStartupStartupView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myStartupDetails}
                        render={() => (
                          <Navigation>
                            <MyStartupDetailsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myStartupChampions}
                        render={() => (
                          <Navigation>
                            <MyStartupChampionsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myStartupFundraising}
                        render={() => (
                          <Navigation>
                            <MyStartupFundraisingView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.myStartupJobs}
                        render={() => (
                          <Navigation>
                            <MyStartupJobsView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.antlerNetwork}
                        render={() => <Redirect to={routes.antlerNetworkFounders} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.antlerNetworkFounders}
                        render={() => (
                          <Navigation>
                            <AntlerNetworkFoundersView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.antlerNetworkStartups}
                        render={() => (
                          <Navigation>
                            <AntlerNetworkStartupsView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.startup + '/:id'}
                        render={(props) => (
                          <Navigation>
                            <StartupPage {...props} />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.investmentPlatform}
                        render={() => <Redirect to={routes.ipDashboard} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.ipDashboard}
                        render={() => (
                          <Navigation>
                            <InvestmentDashboardView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.ipStartupUpdates}
                        render={() => (
                          <Navigation>
                            <IPAntlerStartupsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.ipMyUpdates}
                        render={() => (
                          <Navigation>
                            <IPMyUpdatesView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.ipInvestors}
                        render={() => (
                          <Navigation>
                            <IPInvestorsView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.talentNetwork}
                        render={() => (
                          <Navigation>
                            <TalentNetworkView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.resources}
                        render={() => <Redirect to={routes.resourcesProgram} />}
                      />
                      <PrivateRoute
                        exact
                        path={[
                          routes.resourcesProgram,
                          routes.resourcesProgram + '/:id',
                        ]}
                        render={props => (
                          <Navigation>
                            <ProgramResourcesView {...props} />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.resourcesTech}
                        render={() => (
                          <Navigation>
                            <TechResourcesView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.resourcesLibrary}
                        render={() => (
                          <Navigation>
                            <LibraryView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.events}
                        render={() => <Redirect to={routes.eventsCalendar} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.eventsCalendar}
                        render={() => (
                          <Navigation>
                            <ProgramCalendarView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={[
                          routes.eventsGlobal,
                          routes.eventsGlobal + '/:id',
                        ]}
                        render={props => (
                          <Navigation>
                            <GlobalEventsView {...props} />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.perks}
                        render={() => <Redirect to={routes.partnerships} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.partnerships}
                        render={() => (
                          <Navigation>
                            <PartnershipsView />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.offerPerk}
                        render={() => (
                          <Navigation>
                            <OfferPerkView />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.advisors}
                        render={() => <Redirect to={routes.advisorsExperts} />}
                      />
                      <PrivateRoute
                        exact
                        path={routes.advisorsExperts}
                        render={() => (
                          <Navigation>
                            <AdvisorsView
                              filters={[
                                { label: 'Type', facet: 'type' },
                                {
                                  label: 'Experience (Industry)',
                                  facet: 'expert',
                                },
                                { label: 'Location', facet: 'location' },
                              ]}
                              requiredFilters={
                                'startupExpert:true AND showOnHub:true'
                              }
                              key={routes.advisorsExperts}
                            />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.advisorsIndustryConnectors}
                        render={() => (
                          <Navigation>
                            <AdvisorsView
                              key={routes.advisorsIndustryConnectors}
                              requiredFilters={'showOnHub:true'}
                            />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.advisorsVenturePartners}
                        render={() => (
                          <Navigation>
                            <AdvisorsView
                              filters={[
                                {
                                  label: 'Experience (Industry)',
                                  facet: 'expert',
                                },
                                { label: 'Location', facet: 'location' },
                              ]}
                              requiredFilters={
                                'type:"Venture Partner" AND showOnHub:true'
                              }
                              key={routes.advisorsVenturePartners}
                            />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.nps}
                        render={props => (
                          <Navigation>
                            <NPSView {...props} />
                          </Navigation>
                        )}
                      />

                      {/* <PrivateRoute
                      exact
                      path={routes.settings}
                      render={() => <Navigation> TODO:settings</Navigation>}
                    /> */}

                      <PrivateRoute
                        exact
                        path="/"
                        render={() => <HomeRedirect />}
                      />

                      <PrivateRoute
                        render={() => (
                          <Navigation>
                            <EmptyState message="Page Not Found" />
                          </Navigation>
                        )}
                      />
                    </Switch>
                  </Suspense>
                </CustomBrowserRouter>
              </DndProvider>
            </FiregridProvider>
          </AppProvider>
        </SnackProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
