import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import {
  makeStyles,
  createStyles,
  Typography,
  TextField,
} from '@material-ui/core'
import { cloudFunction, CLOUD_FUNCTIONS } from 'firebase/callables'
import { Loading, EmptyState } from '@antlerengineering/components'
import { CtaButton } from '@antlerengineering/components'
import AuthCard from './AuthCard'
import { auth, googleProvider } from '../../firebase'
import { useSnackContext } from 'samosas'
import { handleGoogleAuth } from './utils'
import GoogleButton from './GoogleButton'

export default function TransferAccountView() {
  const [loadingRequest, setLoadingRequest] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')
  const [error, setError] = useState()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [authType, setAuthType] = useState()
  const handleTransfer = async (id, key, gSuiteAccountVerified) => {
    await cloudFunction(
      CLOUD_FUNCTIONS.transferAccount,
      { transferRequestId: id, transferRequestKey: key, gSuiteAccountVerified },
      async resp => {
        console.log(resp)
        const { newEmail, success, message, jwt } = resp.data
        if (success) {
          setEmail(newEmail)
          setAuthType(resp.data.authType)
          if (authType === 'password') {
            await auth.signInWithCustomToken(jwt)
          } else if (jwt && authType === 'google') {
            await auth.signInWithCustomToken(jwt)
            setLoading(false)
            window.location.replace('/')
          }
        } else {
          setError(message)
        }
        setLoadingRequest(false)
      },
      err => {
        console.log(err)
      }
    )
  }
  const { id, key } = queryString.parse(window.location.search)
  useEffect(() => {
    handleTransfer(id, key, false)
  }, [])
  const snack = useSnackContext()

  if (loadingRequest)
    return <Loading fullScreen message={'Transferring Account...'} />
  if (error) return <EmptyState message={error} fullScreen />
  if (authType === 'google')
    return (
      <AuthCard height={400} loading={loading}>
        <Typography variant="overline">FOUNDER HUB</Typography>
        <Typography variant="body1">
          You have requested to change your email address. Please authenticate
          with your Google account to complete the transfer process.
          <br />
          <b>{email}</b>
        </Typography>

        <GoogleButton
          onClick={async () => {
            setLoading(true)
            try {
              const authUser = await auth.signInWithPopup(googleProvider)
              if (
                authUser.user?.email?.toLowerCase() !==
                (email ?? '').toLowerCase()
              ) {
                throw new Error(`Please login with with ${email}`)
              } else {
                await handleTransfer(id, key, true)
              }
            } catch (error) {
              setLoading(false)
              snack.open({ message: error.message })
            }
          }}
        />
      </AuthCard>
    )
  if (authType === 'password')
    return (
      <AuthCard height={420} loading={loading}>
        <Typography variant="overline">FOUNDER HUB</Typography>
        <Typography variant="body1">
          You have requested to change your email address. Please check the new
          e-mail address,and set a new password.
          <b>{email}</b>
        </Typography>
        <TextField
          label={'Type Password'}
          name={'password'}
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value)
          }}
        />
        <TextField
          type="password"
          label={'Confirm Password'}
          name={'confirmPassword'}
          value={confirmPassword}
          onChange={e => {
            setConfirmPassword(e.target.value)
          }}
        />

        <CtaButton
          onClick={async () => {
            if (auth.currentUser) {
              try {
                if (password === confirmPassword) {
                  setLoading(true)
                  await auth.currentUser.updatePassword(password)
                  setLoading(false)
                  window.location.replace('/')
                } else {
                  snack.open({ message: 'Passwords don’t match' })
                }
              } catch (error) {
                setLoading(false)
                snack.open({ message: error.message })
              }
            }
          }}
        >
          CHANGE EMAIL ACCOUNT
        </CtaButton>
      </AuthCard>
    )
  return <EmptyState message="unhanded error" />
}
