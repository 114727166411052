import React from 'react'
import { useTheme, TextField, MenuItem, Grid } from '@material-ui/core'
import { useAppContext } from 'contexts/AppContext'
import useCollection from 'hooks/useCollection'

const CohortSelector = () => {
  const theme = useTheme()

  const { userDocState, updateUserDoc } = useAppContext()
  const [cohortsState] = useCollection({
    path: 'cohorts',
    orderBy: { field: 'startDate', direction: 'desc' },
    filters:
      userDocState.doc.location[0].toUpperCase() === 'GLOBAL'
        ? []
        : [
            {
              field: 'location',
              operator: 'in',
              value: userDocState.doc.location,
            },
          ],
  })

  const handleChange = async e => {
    if (e.target.value && updateUserDoc) {
      await updateUserDoc({ cohort: e.target.value })
      window.location.reload()
    }
  }

  if (!cohortsState.documents || cohortsState.documents.length === 0)
    return null

  return (
    <TextField
      select
      value={userDocState.doc.cohort}
      onChange={handleChange}
      fullWidth
      label="Cohort"
      variant="outlined"
      margin="dense"
      id="cohort-select"
    >
      {cohortsState.documents.map(doc => (
        <MenuItem
          key={doc.cohort}
          value={doc.cohort}
          id={`cohort-item--${doc.cohort}`}
        >
          <Grid
            container
            wrap="nowrap"
            justify="space-between"
            alignItems="baseline"
            style={theme.typography.body2 as any}
          >
            <Grid item className={'cohort-selector-item-code'}>
              {doc.cohort}
            </Grid>
            <Grid
              item
              style={{ color: theme.palette.text.secondary }}
              className={'cohort-selector-item-location'}
            >
              {doc.location}
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  )
}
export default CohortSelector
