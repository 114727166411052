import React, { useEffect } from 'react'

import { makeStyles, createStyles, Typography } from '@material-ui/core'

import { Loading, Accordion } from '@antlerengineering/components'
import AnnouncementCard from 'components/Card/AnnouncementCard'
import { BasicCard } from '@antlerengineering/components'
import CompleteProfileModal from 'components/CompleteProfileModal'
import Checklist from 'components/Checklist'
import surveySVG from '../assets/survey-visual.svg'

import { useAppContext } from 'contexts/AppContext'
import useCollection from 'hooks/useCollection'

const useStyles = makeStyles(theme =>
  createStyles({
    cardGrid: {
      marginTop: theme.spacing('l'),
      '--num-cards': 1,
    },
    emptyCard: {
      marginTop: theme.spacing('l'),
      maxWidth: 'calc(33rem + var(--spacing-card) * 2)',
      '&:first-child': {
        marginTop: 0,
      },
    },
  })
)

export default function AnnouncementsView() {
  const classes = useStyles()

  const { cohortDoc, userClaims, userDocState } = useAppContext()
  const [announcementsState, announcementsDispatch] = useCollection({
    orderBy: { field: 'date', direction: 'desc' },
  })

  useEffect(() => {
    if (!cohortDoc || !userClaims) return
    announcementsDispatch({
      path: `cohorts/${cohortDoc?.id}/announcements`,
      filters: userClaims?.roles.includes('TEAM')
        ? []
        : [
            {
              field: 'stage',
              operator: 'array-contains',
              value: userDocState.doc.unfunded
                ? 'Unfunded'
                : userDocState.doc.isFunded
                ? 'PhaseTwo'
                : 'PhaseOne',
            },
          ],
    })
  }, [cohortDoc, userClaims])

  if (!cohortDoc || announcementsState.loading) return <Loading />

  const announcements = [...announcementsState.documents]
  if (cohortDoc?.hubAnnouncement)
    announcements.push({ announcement: cohortDoc.hubAnnouncement })

  return (
    <>
      <CompleteProfileModal />
      <Checklist />

      <section>
        {announcements[0] && <AnnouncementCard {...announcements[0]} />}
      </section>

      {announcements.length > 1 && (
        <section className={classes.cardGrid}>
          <Accordion
            title="Past Announcements"
            cards={announcements.slice(1).map(data => (
              <AnnouncementCard key={data.id} {...data} />
            ))}
          />
        </section>
      )}

      {announcements.length === 0 && (
        <section className={classes.cardGrid}>
          <BasicCard
            className={classes.emptyCard}
            body={
              <>
                <Typography variant="overline">BREWING...</Typography>
                <Typography variant="h5">Welcome to the Hub!</Typography>
                <Typography variant="body2">
                  Founder Hub is your one-stop-shop for all information and
                  resources related to the program and beyond.
                </Typography>
                <img src={surveySVG} width={90} height={90} />
                <Typography variant="body2">
                  Currently, there are no big news yet. Meanwhile, you can
                  browse through resources, interact with other members. If you
                  think you have any issues or problems, please contact.
                </Typography>
                <Typography variant="body2">- Team Antler</Typography>
              </>
            }
          />
        </section>
      )}
    </>
  )
}
