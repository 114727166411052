import * as LDClient from "launchdarkly-js-client-sdk";

export const getLaunchDarkByUser = (props) => {
    var user = {
        "key": props.userEmail
    };
    
    var client = LDClient.initialize('61c9a8e87b99e213371bfa71', user);

    var showFeature = false
    
    return new Promise((resolve ,reject)=>{
        client.on('ready', function() {
            //var allFlags = client.allFlags()
            if (client.variation(props.clientVariation, false)) {
                showFeature = true
            }
            resolve(showFeature)
        });
    });
}