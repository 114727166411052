import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

import config from './config'
firebase.initializeApp(config)
const analytics = firebase.analytics()
const auth = firebase.auth()
const db = firebase.firestore()
const bucket = firebase.storage()
const functions = firebase.functions()
const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters(
  {
    prompt: 'select_account',
  }
)

if (process.env.REACT_APP_EMULATOR) {
  console.log('Running in firebase emulator mode')
  db.useEmulator('localhost', 8080)
  auth.useEmulator('http://localhost:9099')
}

export { analytics, auth, db, bucket, functions, googleProvider }
