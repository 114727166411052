import HomeIcon from '@material-ui/icons/Home'
import ProfileIcon from '@material-ui/icons/AccountBox'
import CohortIcon from '@material-ui/icons/Contacts'
import TeamIcon from '@material-ui/icons/GroupWork'
import InvestmentPlatformIcon from 'assets/icons/Investment'
import TalentNetworkIcon from '@material-ui/icons/AccountBox'
import ResourcesIcon from '@material-ui/icons/Folder'
import ForumIcon from '@material-ui/icons/Forum'
import EventsIcon from '@material-ui/icons/Event'
import PartnershipsIcon from '@material-ui/icons/Loyalty'
import AdvisorsIcon from '@material-ui/icons/SupervisedUserCircle'
import SettingsIcon from '@material-ui/icons/Settings'
import JobsIcon from '@material-ui/icons/BusinessCenter'
import PeopleIcon from '@material-ui/icons/People';

import { CustomClaims } from 'contexts/AppContext'

export {
  HomeIcon,
  ProfileIcon,
  CohortIcon,
  TeamIcon,
  InvestmentPlatformIcon,
  TalentNetworkIcon,
  ResourcesIcon,
  ForumIcon,
  EventsIcon,
  PartnershipsIcon,
  AdvisorsIcon,
  SettingsIcon,
  JobsIcon,
  PeopleIcon,
}

export enum routes {
  adminAuth = '/adminAuth',
  setLocation = '/setLocation',
  forgotPassword = '/forgotPassword',
  resetPassword = '/resetPassword',
  transferAccount = '/transferAccount',
  googleAuth = '/googleAuth',
  jwtAuth = '/jwtAuth',
  authLink = '/authLink',
  signIn = '/signIn',
  discourse = '/discourse',

  home = '/home',

  profile = '/profile',
  profilePersonal = '/profile/personal',
  profileProgram = '/profile/program',
  profileStartup = '/profile/startup',
  profileTalentNetwork = '/profile/talentNetwork',

  cohort = '/cohort',
  cohortFounders = '/cohort/founders',
  cohortSubmissions = '/cohort/submissions',
  cohortTeams = '/cohort/teams',

  myTeam = '/myTeam',
  myTeamStartup = '/myTeam/startup',
  myTeamDetails = '/myTeam/details',
  myTeamCoaching = '/myTeam/coaching',

  myStartup = '/myStartup',
  myStartupStartup = '/myStartup/startup',
  myStartupDetails = '/myStartup/details',
  myStartupChampions = '/myStartup/champions',
  myStartupFundraising = '/myStartup/fundraising',
  myStartupJobs = '/myStartup/jobs',


  antlerNetwork = '/antler-network',
  antlerNetworkFounders = '/antler-network/founders',
  antlerNetworkStartups = '/antler-network/startups',

  startup = '/startup',

  investmentPlatform = '/investment',
  ipDashboard = '/investment/dashboard',
  ipStartupUpdates = '/investment/startup-updates',
  ipMyUpdates = '/investment/my-updates',
  ipInvestors = '/investment/investors',

  talentNetwork = '/talentNetwork',

  resources = '/resources',
  resourcesProgram = '/resources/program',
  resourcesStartup = '/resources/startup',
  resourcesTech = '/resources/tech',
  resourcesLibrary = '/resources/library',

  events = '/events',
  eventsCalendar = '/events/calendar',
  eventsBookings = '/events/bookings',
  eventsGlobal = '/events/global',

  perks = '/perks',
  partnerships = '/perks/all',
  offerPerk = '/perks/offer',

  advisors = '/advisors',
  advisorsIndustryConnectors = '/advisors/industryConnectors',
  advisorsExperts = '/advisors/startupExperts',
  advisorsVenturePartners = '/advisors/venturePartners',

  nps = '/nps',

  settings = '/settings',
  signOut = '/signOut',
}

export type Route = {
  showNewBadge?: boolean
  label: string
  showLocationInLabel?: boolean
  route: string
  Icon: typeof HomeIcon
  children?: {
    label: string
    route: string
  }[]
}

export type RouteFunctionProps = {
  userClaims?: CustomClaims
  algoliaKeys?: Record<string, string>
  userDoc?: Record<string, any>
  showAntlerNetwork?: Boolean
}
export type RouteFunction = (props: RouteFunctionProps) => Route | null

const home: RouteFunction = () => ({
  label: 'Home',
  route: routes.home,
  Icon: HomeIcon,
})

const profile: RouteFunction = ({ userClaims, userDoc }) =>
  !userClaims?.roles?.includes('TEAM')
    ? userDoc?.isFunded
      ? { label: 'Profile', route: routes.profileStartup, Icon: ProfileIcon }
      : {
          label: 'Profile',
          route: routes.profile,
          Icon: ProfileIcon,

          children: [
            userDoc?.unfunded && userDoc?.inTalentNetwork
              ? {
                  label: 'Talent Network',
                  route: routes.profileTalentNetwork,
                }
              : null,
            {
              label: 'Personal',
              route: routes.profilePersonal,
            },
            {
              label: 'Program',
              route: routes.profileProgram,
            },
          ].filter(x => x !== null) as Route['children'],
        }
    : null

const cohort: RouteFunction = ({ userClaims, userDoc }) => ({
  label: 'Cohort',
  showLocationInLabel: true,
  route: routes.cohort,
  Icon: CohortIcon,

  children: [
    {
      label: 'Founders',
      route: routes.cohortFounders,
    },
    !userClaims?.roles?.includes('TEAM') && !userDoc?.isFunded
      ? {
          label: 'Submissions',
          route: routes.cohortSubmissions,
        }
      : null,
    !userDoc?.unfunded
      ? {
          label: 'Teams',
          route: routes.cohortTeams,
        }
      : null,
  ].filter(x => x !== null) as Route['children'],
})

const myTeam: RouteFunction = ({ userClaims, userDoc }) =>
  !userClaims?.roles?.includes('TEAM') &&
  !userDoc?.isFunded &&
  !userDoc?.unfunded
    ? {
        label: 'My Team',
        route: routes.myTeam,
        Icon: TeamIcon,

        children: [
          {
            label: 'Startup',
            route: routes.myTeamStartup,
          },
          {
            label: 'Details',
            route: routes.myTeamDetails,
          },
          {
            label: 'Coaching',
            route: routes.myTeamCoaching,
          },
        ],
      }
    : null

const myStartup: RouteFunction = ({ userClaims, userDoc }) =>
  !userClaims?.roles?.includes('TEAM') &&
  userDoc?.isFunded &&
  userDoc?.team !== null
    ? {
        label: 'My Startup',
        route: routes.myStartup,
        Icon: TeamIcon,

        children: [
          {
            label: 'Startup',
            route: routes.myStartupStartup,
          },
          {
            label: 'Details',
            route: routes.myStartupDetails,
          },
          {
            label: 'Champions',
            route: routes.myStartupChampions,
          },
          {
            label: 'Fundraising',
            route: routes.myStartupFundraising,
          },
          {
            label: 'Jobs',
            route: routes.myStartupJobs,
          },
        ],
      }
    : null
  
const antlerNetwork: RouteFunction = ({ userClaims, userDoc, showAntlerNetwork }) => 
showAntlerNetwork &&
(
  (
    userClaims?.roles?.includes('ADMIN')
  ) ||
  (
    !userClaims?.roles?.includes('TEAM') &&
    userDoc?.isFunded &&
    userDoc?.team !== null
  )
)

?
  {
    label: 'Antler Network',
    showLocationInLabel: false,
    route: routes.antlerNetwork,
    Icon: PeopleIcon,
    showNewBadge: userDoc?.antlerNetworkStage > 1 ? false : true,
    children: [
      {
        label: 'Founders',
        route: routes.antlerNetworkFounders,
      },
      {
        label: 'Startups',
        route: routes.antlerNetworkStartups,
      },
    ].filter(x => x !== null) as Route['children'],
  }
:
  null

const investmentPlatform: RouteFunction = ({ userClaims, userDoc }) =>
  !userClaims?.roles?.includes('TEAM') &&
  userDoc?.isFunded &&
  userDoc?.team !== null
    ? {
        label: 'Investment Platform',
        route: routes.investmentPlatform,
        Icon: InvestmentPlatformIcon,

        children: [
          // {
          //   label: 'Dashboard',
          //   route: routes.ipDashboard,
          // },
          // {
          //   label: 'Startup Updates',
          //   route: routes.ipStartupUpdates,
          //   disabled: true,
          // },
          {
            label: 'My Updates',
            route: routes.ipMyUpdates,
          },
          // {
          //   label: 'Investors',
          //   route: routes.ipInvestors,
          //   disabled: true,
          // },
        ],
      }
    : null

const talentNetwork: RouteFunction = ({ userDoc, userClaims }) =>
  userClaims?.roles?.includes('TEAM') || userDoc?.isFunded
    ? {
        label: 'Talent Network',
        route: routes.talentNetwork,
        Icon: TalentNetworkIcon,
      }
    : null

const resources: RouteFunction = ({ userDoc }) => ({
  label: 'Resources',
  route: routes.resources,
  Icon: ResourcesIcon,

  children: [
    {
      label: 'Library',
      route: routes.resourcesLibrary,
    },
    !userDoc?.unfunded
      ? {
          label: 'Program',
          route: routes.resourcesProgram,
        }
      : null,
    // {
    //   label: 'Tech',
    //   route: routes.resourcesTech,
    // },
  ].filter(x => x !== null) as Route['children'],
})

const jobs: RouteFunction = ({ userDoc }) =>
  userDoc?.unfunded
    ? {
        label: 'Jobs',
        route: 'https://antler.co/jobs',
        externalRoute: true,
        Icon: JobsIcon,
      }
    : null

const events: RouteFunction = ({ userDoc }) =>
  userDoc?.unfunded
    ? {
        label: 'Events',
        route: routes.eventsGlobal,
        Icon: EventsIcon,
      }
    : {
        label: 'Events',
        route: routes.events,
        Icon: EventsIcon,

        children: [
          {
            label: 'Program Calendar',
            route: routes.eventsCalendar,
          },
          {
            label: 'Global Events',
            route: routes.eventsGlobal,
          },
        ],
      }

const partnerships: RouteFunction = ({ userClaims, userDoc }) =>
  !userClaims?.unFunded
    ? {
        label: 'Perks',
        route: routes.perks,
        Icon: PartnershipsIcon,
        children: userDoc?.isFunded
          ? [
              {
                label: 'All Perks',
                route: routes.partnerships,
              },
              {
                label: 'Offer a Perk',
                route: routes.offerPerk,
              },
            ]
          : undefined,
      }
    : null

const advisors: RouteFunction = ({ userDoc }) =>
  !userDoc?.unfunded
    ? {
        label: 'Advisors',
        showLocationInLabel: true,
        route: routes.advisors,
        Icon: AdvisorsIcon,

        children: [
          {
            label: 'Industry Connectors',
            route: routes.advisorsIndustryConnectors,
          },
          {
            label: 'Startup experts',
            route: routes.advisorsExperts,
          },
          {
            label: 'Venture Partners',
            route: routes.advisorsVenturePartners,
          },
        ],
      }
    : null

// const settings = {
//   label: 'Settings',
//   route: routes.settings,
//   Icon: SettingsIcon,
// }

export const getNavItems = (props: RouteFunctionProps): Route[] => {
  const ordered = [
    home,
    profile,
    cohort,
    myTeam,
    myStartup,
    antlerNetwork,
    investmentPlatform,
    jobs,
    talentNetwork,
    resources,
    events,
    partnerships,
    advisors,
  ]

  // Call all route functions
  const result: Route[] = []
  ordered.forEach(fn => {
    const res = fn.call(null, props)
    // Only display if not null
    if (res !== null) result.push(res)
  })

  return result
}
