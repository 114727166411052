import React from 'react'
import { differenceInCalendarWeeks } from 'date-fns'

import { makeStyles, createStyles, useTheme } from '@material-ui/core'
import { fade } from '@material-ui/core'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import googleCalendarPlugin from '@fullcalendar/google-calendar'

import { useAppContext } from 'contexts/AppContext'
import { EventsIcon } from 'constants/routes'
import { Loading, EmptyState } from '@antlerengineering/components'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .fc': {
        '--fc-border-color': theme.palette.divider,
        '--fc-page-bg-color': theme.palette.background.default,
        '--fc-neutral-bg-color': theme.palette.background.paper,
        '--fc-today-bg-color': fade(
          theme.palette.primary.main,
          theme.palette.action.hoverOpacity
        ),

        '& .fc-header-toolbar': {
          backgroundColor: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.divider}`,

          position: 'sticky',
          top: 64,
          zIndex: 10,

          height: 40 + theme.spacing('s') * 2,
          marginBottom: -1,
          marginTop: -theme.spacing('s'),
          padding: theme.spacing('s', 0),
        },

        '& .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > *': {
          top: 64 + 40 + theme.spacing('s') * 2,
          zIndex: 11,
        },

        '& .fc-toolbar-title': theme.typography.h4,

        '& .fc-button-primary': {
          ...theme.typography.button,
          color: theme.palette.primary.main,

          height: 36,
          borderRadius: 18,

          backgroundColor: 'transparent',
          border: 'none',
          // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
          transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.short,
          }),

          '&:not(:disabled):hover, &:not(:disabled).fc-button-active': {
            color: theme.palette.primary.main,
            backgroundColor: fade(
              theme.palette.primary.main,
              theme.palette.action.hoverOpacity
            ),
          },
          '&:not(:disabled):active': {
            color: theme.palette.primary.main,
            backgroundColor: fade(
              theme.palette.primary.main,
              theme.palette.action.disabledOpacity
            ),
          },

          '&:disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },

      '& a': {
        color: theme.palette.text.secondary,
        fontWeight: 'inherit',
      },

      '& .fc-col-header-cell': {
        '& a': {
          ...theme.typography.button,
          color: theme.palette.text.disabled,
        },
        borderBottom: 0,

        '& .fc-col-header-cell-cushion': { padding: theme.spacing('xxs', 0) },
      },

      '& .fc-daygrid-day-top': {
        justifyContent: 'center',
      },
      '& .fc-daygrid-day-number': {
        ...theme.typography.button,
        color: theme.palette.text.primary,
        letterSpacing: 0,

        padding: 0,
        width: 32,
        height: 32,
        borderRadius: 16,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          textDecoration: 'none !important',
          backgroundColor: fade(
            theme.palette.text.primary,
            theme.palette.action.hoverOpacity
          ),
        },
      },
      '& .fc-daygrid-week-number': {
        ...theme.typography.button,
        '& a': { color: theme.palette.text.primary },
        padding: theme.spacing('xxs'),
      },

      '& .fc-daygrid-event': {
        '&.fc-event-start, &.fc-event-end': {
          margin: theme.spacing(0.25, 0, 0.25, 0.5),
        },
        padding: theme.spacing(0.5, 0, 0.5, 1),

        '& .fc-daygrid-event-dot': { display: 'none' },
        '& .fc-event-time': { marginRight: theme.spacing(1) },
        '& .fc-event-title': { fontWeight: 600 },
      },
      '& .fc-daygrid-block-event': {
        border: 0,

        '&.fc-daygrid-event.fc-event-start, &.fc-daygrid-event.fc-event-end': {
          marginRight: theme.spacing(0.5),
        },

        backgroundColor: theme.palette.antler.aGray[200] + ' !important',
        '&:hover': {
          backgroundColor: theme.palette.antler.aGray[300] + ' !important',
        },

        '& .fc-event-main': { color: 'inherit' },

        '& .fc-event-time': { fontWeight: 'normal' },
        '& .fc-event-time, & .fc-event-title': { padding: 0 },
      },

      '& .fc-timegrid-axis, & .fc-timegrid-slot-label': {
        ...theme.typography.button,
        color: theme.palette.text.secondary,
        textTransform: 'none',
      },
      '& .fc-timegrid-event': {
        '& .fc-event-time': { fontWeight: 600 },
      },

      '& .fc-list': { border: 0 },
      '& .fc-list-table th, & .fc-list-table td': { border: 0 },
      '& .fc-list-sticky .fc-list-day > *': {
        top: 64 + 40 + theme.spacing('s') * 2,
      },
      '& .fc-list-day-text, & .fc-list-day-side-text': {
        ...theme.typography.button,
        color: theme.palette.text.disabled,
      },
      '& .fc-list-event-title a': { fontWeight: 600 },
    },
  })
)

export default function ProgramCalendarView() {
  const classes = useStyles()
  const theme = useTheme()
  const { cohortDoc } = useAppContext()

  if (!cohortDoc) return <Loading />

  if (!cohortDoc.calendarIframe && !cohortDoc.calendarId)
    return (
      <EmptyState
        Icon={EventsIcon}
        message="Program Calendar Coming Soon"
        description="Program calendar will be added here closer to program kick-off."
      />
    )

  if (cohortDoc.calendarIframe)
    return (
      <div dangerouslySetInnerHTML={{ __html: cohortDoc.calendarIframe }} />
    )

  return (
    <div className={classes.root}>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          googleCalendarPlugin,
        ]}
        initialView="dayGridMonth"
        googleCalendarApiKey={
          process.env.REACT_APP_FIREBASE_PROJECT_WEB_API_KEY
        }
        events={{ googleCalendarId: cohortDoc.calendarId }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
          separator: '–',
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        }}
        firstDay={1}
        eventColor={theme.palette.antler.aGray[700]}
        customButtons={
          cohortDoc.calendarUrl
            ? {
                subscribe: {
                  text: 'subscribe',
                  click: () => window.open(cohortDoc.calendarUrl),
                },
              }
            : undefined
        }
        headerToolbar={{
          left: 'prev,next today subscribe',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        navLinks
        height="auto"
        weekNumbers
        weekNumberCalculation={
          cohortDoc.startDate
            ? d =>
                differenceInCalendarWeeks(d, cohortDoc.startDate.toDate()) + 1
            : undefined
        }
        // Open link in new tab
        eventClick={info => {
          if (info.event.url) {
            window.open(info.event.url)
            info.jsEvent.preventDefault()
          }
        }}
      />
    </div>
  )
}
