import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import _find from 'lodash/find'

import {
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Snackbar,
  Badge,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import useRouter from '../../hooks/useRouter'
import { useAppContext } from 'contexts/AppContext'
import { routes } from 'constants/routes'

const useStyles = makeStyles(theme =>
  createStyles({
    listItem: {
      color: theme.palette.text.secondary,
      minHeight: 48,
      transition: theme.transitions.create(['background-color', 'color']),
      '& $listItemIcon': { transition: theme.transitions.create('color') },
    },
    listItemSelected: {
      color: theme.palette.text.primary,
      '& $listItemIcon': { color: theme.palette.text.primary },
    },
    listItemIcon: {},
    listItemText: {
      ...theme.typography.button,
      display: 'block',
      color: 'inherit',
    },

    dropdownIcon: {
      color: theme.palette.action.active,
      transition: theme.transitions.create('transform'),
    },
    dropdownIconOpen: { transform: 'rotate(180deg)' },

    childListItem: {
      minHeight: 40,
      paddingLeft: theme.spacing(11),
    },
    childListItemText: {
      ...theme.typography.overline,
      display: 'block',
      color: 'inherit',
    },
  })
)

export interface INavSidebarItemProps {
  item: any
}

export default function NavSidebarItem({ item }: INavSidebarItemProps) {
  const classes = useStyles()
  const router = useRouter()

  const [openChildren, setOpenChildren] = useState(
    '/' + router.location.pathname.toLowerCase().split('/')[1] ===
      item.route.toLowerCase()
  )

  useEffect(() => {
    if (!Array.isArray(item.children) || item.children.length === 0) return
    const childItem = _find(
      item.children,
      item =>
        item.route.toLowerCase() === router.location.pathname.toLowerCase()
    )
    if (!!childItem)
      document.title = `${childItem.label} | ${item.label} | Antler Hub`
  }, [router.location.pathname])

  const { userClaims, userDocState } = useAppContext()
  const disabled =
    !userClaims?.roles.includes('TEAM') &&
    !userDocState.doc.isComplete &&
    item.route !== routes.home
  const [showSnack, setShowSnack] = useState(false)

  const snackbar = (
    <Snackbar
      open={showSnack}
      onClose={() => setShowSnack(false)}
      message="Please complete the onboarding survey to enable this feature"
    />
  )

  if (!Array.isArray(item.children) || item.children.length === 0)
    return (
      <li>
        <ListItem
          button
          selected={
            router.location.pathname.toLowerCase() === item.route.toLowerCase()
          }
          component={item.externalRoute ? 'a' : Link}
          to={!item.externalRoute ? item.route : undefined}
          href={item.externalRoute && item.route}
          target={item.externalRoute ? '_blank' : undefined}
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
          disabled={disabled}
          onClick={
            disabled
              ? e => {
                  e.preventDefault()
                  setShowSnack(true)
                }
              : undefined
          }
        >
          <ListItemIcon className={classes.listItemIcon}>
            <item.Icon />
          </ListItemIcon>

          <ListItemText
            primary={item.label}
            classes={{ primary: classes.listItemText }}
          />

          <Badge
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',}}
            variant="standard"
            color="primary"
            badgeContent="New"
            invisible={!item.showNewBadge}>
          </Badge>

          {item.children && <ArrowDropDownIcon />}
        </ListItem>
        {snackbar}
      </li>
    )

  return (
    <li>
      <ListItem
        button
        selected={
          router.location.pathname.toLowerCase() === item.route.toLowerCase()
        }
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        onClick={
          disabled
            ? e => {
                e.preventDefault()
                setShowSnack(true)
              }
            : () => setOpenChildren(state => !state)
        }
        disabled={disabled}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <item.Icon />
        </ListItemIcon>

        <ListItemText
          primary={item.label}
          classes={{ primary: classes.listItemText }}
        />

        <Badge
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',}}
          variant="standard"
          color="primary"
          badgeContent="New"
          invisible={!item.showNewBadge}>
        </Badge>

        <ArrowDropDownIcon
          className={clsx(
            classes.dropdownIcon,
            openChildren && classes.dropdownIconOpen
          )}
        />
      </ListItem>
      {snackbar}

      <Collapse in={openChildren}>
        <List>
          {item.children.map(childItem => (
            <li key={childItem.route}>
              <ListItem
                button
                selected={
                  router.location.pathname.toLowerCase() ===
                  childItem.route.toLowerCase()
                }
                component={item.externalRoute ? 'a' : Link}
                to={!childItem.externalRoute ? childItem.route : undefined}
                href={childItem.externalRoute && childItem.route}
                classes={{
                  root: clsx(classes.listItem, classes.childListItem),
                  selected: classes.listItemSelected,
                }}
              >
                <ListItemText
                  primary={childItem.label}
                  classes={{ primary: classes.childListItemText }}
                />
              </ListItem>
            </li>
          ))}
        </List>
      </Collapse>
    </li>
  )
}
