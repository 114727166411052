import React, { useEffect, useState } from 'react'
import useAlgolia from 'use-algolia'

import { Typography } from '@material-ui/core'
import { CtaButton } from '@antlerengineering/components'

import AuthCard from './AuthCard'
import { handleGoogleAuth, signOut } from './utils'
import GoogleButton from './GoogleButton'
import { useSnackContext } from 'samosas'
import useCollection from 'hooks/useCollection'
import MultiSelect from '@antlerengineering/multiselect'
import { getJWTWithUID } from 'firebase/callables'
import { auth } from '../../firebase'
export default function AdminAuthView() {
  useEffect(() => {
    //sign out user on initial load
    signOut()
  }, [])
  const [loading, setLoading] = useState(false)
  const snack = useSnackContext()
  const [adminUser, setAdminUser] = useState()
  const [uid, setUid] = useState('')
  const [cohortsCollection, cohortsDispatch] = useCollection({})
  const [employeesCollection, employeesDispatch] = useCollection({})
  const [algoliaState, requestDispatch, getMore, setAlgoliaConfig] = useAlgolia(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    '',
    'founders',
    {
      hitsPerPage: 500,
    }
  )

  const [algoliaKey, setAlgoliaKey] = useState('')

  useEffect(() => {
    setAlgoliaConfig({ searchKey: algoliaKey })
  }, [algoliaKey])

  useEffect(() => {
    if (adminUser) {
      cohortsDispatch({ path: 'cohorts' })
      employeesDispatch({ path: 'employees' })
    }
  }, [adminUser])
  const handleAuth = async (uid: string) => {
    setLoading(true)
    const resp = await getJWTWithUID(uid)
    setLoading(false)
    if (resp.data.success) {
      snack.open({ message: resp.data.message, severity: 'success' })
      await auth.signInWithCustomToken(resp.data.jwt)
      window.location.href = '/profile'
    }
  }

  return (
    <AuthCard height={400} loading={loading}>
      <Typography variant="overline">Admin Authentication</Typography>
      {adminUser === undefined ? (
        <>
          <Typography variant="body1">
            Please select an admin account to authenticate
          </Typography>
          <GoogleButton
            onClick={() => {
              handleGoogleAuth(
                (authUser, roles) => {
                  if (roles.includes('ADMIN') || roles.includes('OPS')) {
                    setAdminUser(authUser.user)
                  } else {
                    snack.open({ message: 'this account is not an admin' })
                    signOut()
                  }
                },
                (error: Error) => {
                  snack.open({ message: error.message })
                }
              )
            }}
          />
        </>
      ) : (
        <>
          {employeesCollection.documents.length !== 0 && (
            <MultiSelect
              multiple={false}
              value={''}
              onChange={v => {
                handleAuth(v as string)
              }}
              options={employeesCollection.documents
                .map(doc => ({
                  value: doc.id,
                  label: `${doc.firstName} ${doc.lastName}`,
                }))
                .filter(option => option.value && option.label)}
              label={'Select an employee'}
              labelPlural={'employees'}
            />
          )}
          {cohortsCollection.documents.length !== 0 && (
            <MultiSelect
              multiple={false}
              value={algoliaKey}
              onChange={v => {
                setAlgoliaKey(v as string)
              }}
              options={cohortsCollection.documents
                .map(doc => ({
                  value: doc.algoliaKey,
                  label: doc.cohort,
                }))
                .filter(option => option.value && option.label)}
              label={'Select a cohort'}
              labelPlural={'cohorts'}
            />
          )}

          {algoliaKey !== '' && (
            <MultiSelect
              multiple={false}
              value={uid}
              onChange={v => {
                setUid(v as string)
              }}
              options={
                algoliaState.response
                  ? algoliaState.response.hits.map(doc => ({
                      value: doc.objectID,
                      label: `${doc.firstName} ${doc.lastName}`,
                    }))
                  : []
              }
              label={'Select a founder'}
              labelPlural={'founders'}
            />
          )}
          <CtaButton disabled={uid === ''} onClick={() => handleAuth(uid)}>
            Sign in as founder
          </CtaButton>
        </>
      )}
    </AuthCard>
  )
}
