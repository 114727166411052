import { Grid, Button } from '@material-ui/core'
import { Modal, Friction } from '@antlerengineering/components'

import { useAppContext } from 'contexts/AppContext'
import surveyVisual from '../assets/survey-visual.svg'

export default function CompleteProfileModal() {
  const { userDocState, updateUserDoc } = useAppContext()

  const onboardingComplete = userDocState.doc.isComplete
  if (onboardingComplete) return null

  const userType = userDocState.path.split('/')[0]

  return (
    <Modal
      title="Welcome to Antler Hub"
      body={
        <>
          Antler Hub will be your one-stop-shop for all information and
          resources related to the program and beyond.
          <br />
          <img
            src={surveyVisual}
            alt="Questionnaire graphic"
            style={{ float: 'right', margin: 16 }}
          />
          <br />
          Once you complete the onboarding questionnaire, you’ll unlock all the
          features and start using the Founder Hub.
          <br />
          <br />
          Once your answers are reviewed, the program team will unlock other
          features in Antler Hub to help you get to know other founders in your
          cohort.
        </>
      }
      footer={
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: 24 }}
        >
          <Grid item>
            <Button
              variant="contained"
              component="a"
              href={`https://fireform.antler.co/submit?path=${encodeURI(
                `${userType}/${userDocState.doc.id}`
              )}&form=${userType}Onboarding`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Onboard to Antler Hub
            </Button>
          </Grid>
          <Grid item>
            <Friction
              message={{
                title: 'Confirm you have completed onboarding',
                body: (
                  <>
                    If you have not filled in essential information, such as
                    your name and email, you may break Antler Hub.
                    <br />
                    <br />
                    Please only use this override if you have completed
                    onboarding and this has not been reflected correctly on
                    Antler Hub.
                  </>
                ),
              }}
            >
              <Button
                onClick={() => {
                  if (updateUserDoc) updateUserDoc({ isComplete: true })
                }}
              >
                I Have Completed Onboarding
              </Button>
            </Friction>
          </Grid>
        </Grid>
      }
      onClose={() => {}}
      maxWidth="xs"
      hideCloseButton
      disableEscapeKeyDown
      disableBackdropClick
    />
  )
}
