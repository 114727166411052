import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAppContext } from 'contexts/AppContext'
import { routes } from 'constants/routes'
import { Loading } from '@antlerengineering/components'

export default function HomeRedirect() {
  const { navItems } = useAppContext()

  if (navItems.length === 0) return <Loading fullScreen />

  return <Redirect to={routes.home} />
}
