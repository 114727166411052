import React, { useEffect, useState } from 'react'
import { Loading, EmptyState } from '@antlerengineering/components'
import queryString from 'query-string'
import { discourseSSO } from '../firebase/callables'

const DiscourseSSO = () => {
  const [error, setError] = useState()
  const generateAuthKey = async (sso, sig) => {
    const resp = await discourseSSO(sso, sig)
    if (resp.data.success === false) {
      setError(resp.data.error)
    } else {
      if (resp.data !== null) {
        window.location.replace(
          `https://forum.antler.co/session/sso_login?${resp.data}`
        )
      }
    }
  }
  const parsed = queryString.parse(window.location.search)
  useEffect(() => {
    const { sso, sig } = parsed
    if (sso && sig) {
      generateAuthKey(sso, sig)
    }
  }, [parsed.sso])

  if (error) return <EmptyState title={error} />
  return <Loading message="Authenticating" fullScreen />
}

export default DiscourseSSO
