import React from 'react'
import moment from 'moment'

import { Button } from '@material-ui/core'

import {
  BasicCard,
  CardSubheading,
  RenderedHtml,
} from '@antlerengineering/components'

export interface IAnnouncementCardProps {
  date?: firebase.default.firestore.Timestamp
  announcement: string
  buttonLabel?: string
  buttonUrl?: string
}

export default function AnnouncementCard({
  date,
  announcement,
  buttonLabel,
  buttonUrl,
}: IAnnouncementCardProps) {
  return (
    <BasicCard
      body={
        <>
          {date && (
            <CardSubheading>
              {moment(date.toDate()).format('dddd, D MMMM YYYY')}
            </CardSubheading>
          )}

          <RenderedHtml html={announcement} style={{ flexGrow: 1 }} />

          {buttonUrl && (
            <div>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href={buttonUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonLabel || buttonUrl}
              </Button>
            </div>
          )}
        </>
      }
      style={{ maxWidth: 'calc(33rem + var(--spacing-card) * 2)' }}
    />
  )
}
